.carousel-indicators {
    bottom: 2em;

    >button {
        border: solid 1px #000;
        background-color: #fff !important;
    }

    >button[disabled] {
        background-color: #000 !important;
    }
}