$font-prefix: '/assets/fonts';
@import "ibmPlexMono";
@import "_material-icons-import.scss";

@font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("/assets/fonts/inter/Inter-roman.var.woff2?v=3.18") format("woff2");
}

@font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("/assets/fonts/inter/Inter-italic.var.woff2?v=3.18") format("woff2");
}