body {
    --swan-bg-default-rgb: 241, 245, 249;
    --swan-border-rgb: 226, 232, 240;
}

/* 1. Core */
@import 'core/fonts/fonts';

/* 2. Components */
@import 'components/yukawa';
@import 'components/carousel';

/* 3. Overrides */
@import 'overrides/angular-material';
@import 'overrides/yukawa';