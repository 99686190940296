/* You can add global styles to this file, and also import other style files */
html {
    background-color: #000;
    height: 100vh;
    margin: 0px;
    padding: 0px;
}

body {
    margin: 0;
    padding: 0;
}

.cdk-overlay-backdrop {
    background: #000 !important;
}

// should wrap description Fields (that contain html) 
.formattedText {
    ul {
        list-style: disc;
        padding-left: 2em;
        margin: initial;
    }
}